import $ from 'jquery';
import 'slick-carousel';

function turbolinksCheck() {
  return (typeof Turbolinks == 'undefined') ? 'DOMContentLoaded' : 'turbolinks:load';
}

let initializer = turbolinksCheck();

// asNavFor fix
if (typeof Turbolinks !== 'undefined') {
  document.addEventListener(
    'turbolinks:before-visit',
    function() {
      if ($('.home-testimonials-content__slick').length && $('.home-testimonials-images__slick').length) {
        $('.home-testimonials-content__slick').slick('unslick');
        $('.home-testimonials-images__slick').slick('unslick');
      }
    }
  );
}

function getSlickyWithIt() {
  if ($('.page-banners-wrap').length) {
    $('.page-banners-wrap').slick({
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      slidesToShow: 1,
      dotsClass: 'page-banners-wrap-dots'
    });
  }

  if ($('.page-content-aside__slick').length) {
    $('.page-content-aside__slick').slick({
      adaptiveHeight: true,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'page-content-aside-slick-dots'
    });
  }


  if ($('.home-testimonials-content__slick').length) {
    $('.home-testimonials-content__slick').slick({
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'home-testimonials-content-slick-dots',
      prevArrow: '.home-testimonials-content-slick__arrow--previous',
      nextArrow: '.home-testimonials-content-slick__arrow--next'
    });
  }

  if ($('.footer-testimonials-content__slick').length) {
    $('.footer-testimonials-content__slick').slick({
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'footer-testimonials-content-slick-dots',
      prevArrow: '.footer-testimonials-content-slick__arrow--previous',
      nextArrow: '.footer-testimonials-content-slick__arrow--next'
    });
  }
}

document.addEventListener(
  initializer,
  getSlickyWithIt
);
